import Moment from 'moment';

const request = async (url, { method = "GET", params } = {}, sorter = null) => {
  let token = localStorage.getItem("@App:token");

  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  
  if (token !== 'undefined') {
    headers.Authorization = `Bearer ${token}`;
  }

  let requestOptions = {
    method,
    mode: "cors",
    headers: headers,
    redirect: "follow",
  };
  
  if (method === "GET") {
    url += "?" + new URLSearchParams(params).toString();
  }

  if (
    sorter !== undefined &&
    sorter !== null &&
    Object.keys(sorter).length > 0
  ) {
    const orderBy = sorter?.order === "ascend" ? "asc" : "desc";
    url += `&order[${sorter?.field}]=${orderBy}`;
  }

  if (method !== "GET") {
    requestOptions.body = JSON.stringify(params);
  }

  try {
    const response = await fetch(url, requestOptions);
    if (response.headers.get("Content-Type") === "text/csv; charset=UTF-8") {
      return response;
    } else {
      return response.json();
    }
  } catch (error) {
    console.log("error >>", url, error);
  }
};

export const get = (url, params, sorter) => request(url, { params }, sorter);
export const post = (url, params) => request(url, { method: "POST", params });
export const del = (url, params) => request(url, { method: "DELETE", params });
export const put = (url, params) => request(url, { method: "PUT", params });
export const patch = (url, params) => request(url, { method: "PATCH", params });

export const formatDateTime = (date) => {
  return Moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export const getCurrentDateTime = () => {
  return Moment();
}

export const formatDateToISO8601 = (date) => {
  // const d = new Date(date);
  // return Moment(d).format('DD-MMM-YYYY');
  return date.split(" ")[0].split("/").reverse().join('-');
}

export const formatDate = (date) => {
  return Moment(date).format('DD/MM/YYYY');
}

export const formatDateOnlyTime = (date) => {
  return Moment(date).format('H:s');
}

export function isEmpty(str) {
  return (
    str === undefined ||
    str === null ||
    !str ||
    (typeof str === "string" && str.trim().length === 0)
  );
}

export function getValueObjectSelect(value) {
  if (value?.value !== undefined) {
    return value?.value;
  }
  
  return value;
}

export function prepareOptionValue(data) {
  if (! isEmpty(data?.value) && ! isEmpty(data.children)) {
    return {value: data?.value, label: data.children};
  }
  
  return data;
}

export function getItemsNotUndefined(obj) {
  let objectData = Object.entries(obj);
  let objectNotUndefined = [];

  objectData.forEach((data) => {
    if (data[1] !== undefined && data[1] !== null) {
      objectNotUndefined[data[0]] = data[1];
      objectNotUndefined.length += 1;
    }
  });
  return Object.assign({}, objectNotUndefined);
}

export function onReadyResponse(response, notify, targetMessage, messageSuccess, onLoading, onReloadGrid, reloadGrid, form, type)
{
  if (response?.success === true) {
    if (! isEmpty(notify)) {
      if (type !== undefined && type === 'notification') {
        notify("success", targetMessage, messageSuccess, [], 'notification');
      } else {
        notify("success", targetMessage, messageSuccess);
      }
    }

    if (! isEmpty(onReloadGrid)) {
      onReloadGrid(! reloadGrid);
    }

    if (! isEmpty(form)) {
      form.resetFields();
    }

    if (! isEmpty(onLoading)) {
      onLoading(false);
    }
  } else {
    if (! isEmpty(notify)) {
      if (type !== undefined && type === 'notification') {
        notify("error", targetMessage,  response.message, response.errors, 'notification');
      } else {
        notify("error", targetMessage,  response.message, response.errors);
      }
    }

    if (! isEmpty(onLoading)) {
      onLoading(false);
    }
  }
}

export function getListErrorsLaravel(list)
{
  if (list instanceof Object && ! isEmpty(list)) {
    let errors = [];
    const listErrors = Object.values(list);
    listErrors.map((error) => {
      errors.push(error[0]);
      return error;
    })

    return errors;
  }

  return [];
}

export function prepareFiltersForTableWithSearchPaginated(filters, search) {
  const filtersMerged = [];

  let verifyExistsSearch = false;
  filters.map((filter) => {
    if (search.column === filter.column) {
      filter.value = search.value;
      verifyExistsSearch = true;
    }

    if (!isEmpty(filter.value)) {
      filtersMerged.push(filter);
    }
  });

  if (verifyExistsSearch === false) {
    filtersMerged.push(search);
  }

  return filtersMerged;
}

export function onlyNumbers(value) {
  return value.replace(/[^0-9]/g, "");
}

export const applyMask = (value, mask) => {
  const onlyNums = value.replace(/[^\d]/g, "");

  // Se não houver números, retorne uma string vazia
  if (onlyNums.length === 0) {
    return "";
  }

  let maskedValue = "";
  let valueIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    const maskChar = mask[i];
    const valueChar = onlyNums[valueIndex];

    if (maskChar === "#") {
      if (valueChar !== undefined && !isNaN(Number(valueChar))) {
        maskedValue += valueChar;
        valueIndex++;
      } else {
        break;
      }
    } else {
      maskedValue += maskChar;
      if (valueChar === maskChar) {
        valueIndex++;
      }
    }
  }

  return maskedValue;
};

export const formatAmount = (price) => {
  return Intl.NumberFormat('pr-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price);
}

export const getTermRecurrent = (recurrent_type) => {
  
  if (recurrent_type === 'MENSAL') {
    return 'Mensalidade';
  } else if (recurrent_type === 'TRIMESTRAL') {
    return 'Trimestralidade';
  } else if (recurrent_type === 'SEMESTRAL') {
    return 'Semestralidade';
  } else if (recurrent_type === 'ANUAL') {
    return 'Anualidade';
  }

  return recurrent_type;
}

export const getNameInitial = (name) => {
  if (name !== undefined) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  return null;
}

export const getMediaMensal = (totalMensal, totalTrimestral, totalSemestral, totalAnual) => {
  return (totalMensal) + ((totalTrimestral * 4) / 12) + ((totalSemestral * 2) / 12) + (totalAnual / 12);
} 

export const getTitleGroupTemplate = (group) => {
  if (group === 'informative') {
    return 'Informativo';
  } else if (group === 'newsletter') {
    return 'Newsletter';
  } else if (group === 'formalization') {
    return 'Formalização';
  }

  return group;
}

export const getColorGroupTemplate = (group) => {
  if (group === 'informative') {
    return 'blue';
  } else if (group === 'newsletter') {
    return 'green';
  } else if (group === 'formalization') {
    return 'yellow';
  }

  return 'gray';
}

export const getSalesFunnelCardStatusNameByCode = (code) => {
  if (code === 'EM_CONVERSA') {
    return 'Em conversa';
  } else if (code === 'NEGOCIO_FECHADO') {
    return 'Negócio Fechado';
  } else if (code === 'NEGOCIO_PERDIDO') {
    return 'Negócio Perdido';
  } else if (code === 'EM_NEGOCIACAO') {
    return 'Em negociação';
  } else if (code === 'CONVERSA_ESFRIADA') {
    return 'Conversa Esfriada';
  }

  return code;
}

export function getCompanyIdByFormValues(values) {
  if (!isEmpty(values?.company_id?.value)) {
    return values?.company_id?.value;
  }

  return values?.company_id;
}

export function diasRestantes(dataFutura) {
  // Converter a data recebida para o formato de objeto Date
  const dataFut = new Date(dataFutura);
  const hoje = new Date();

  // Calcular a diferença de tempo em milissegundos
  const diffTempo = dataFut - hoje;

  // Se a data já passou, retornar 0
  if (diffTempo <= 0) {
      return 0;
  }

  // Converter a diferença de milissegundos para dias
  const diasRestantes = Math.ceil(diffTempo / (1000 * 60 * 60 * 24));

  return diasRestantes;
}

export function capitalizarPrimeiraLetra(texto) {
  // Verificar se o parâmetro é uma string
  if (typeof texto !== 'string') {
      return "A";
  }

  // Verificar se a string está vazia
  if (texto.length === 0) {
      return "A";
  }

  // Converter a primeira letra para maiúscula e manter o restante inalterado
  const primeiraLetraMaiuscula = texto.charAt(0).toUpperCase();

  return primeiraLetraMaiuscula;
}

export function converterHoraParaTexto(horaString) {
  // Verifica se o parâmetro é uma string no formato correto de "hh:mm:ss"
  if (typeof horaString !== 'string' || !/^\d{2}:\d{2}:\d{2}$/.test(horaString)) {
      return "Formato inválido.";
  }

  // Separar horas, minutos (ignorando os segundos)
  const [horas, minutos] = horaString.split(':').map(Number);

  // Construir a string resultante
  let resultado = '';

  // Adicionar horas, verificando singular/plural
  if (horas > 0) {
      resultado += horas === 1 ? `${horas} hora` : `${horas} horas`;
  }

  // Adicionar "e" se houver horas e minutos
  if (horas > 0 && minutos > 0) {
      resultado += ' e ';
  }

  // Adicionar minutos, verificando singular/plural
  if (minutos > 0) {
      resultado += minutos === 1 ? `${minutos} minuto` : `${minutos} minutos`;
  }

  // Se não houver nem horas nem minutos, retorna "0 minutos"
  if (!resultado) {
      return '0 minutos';
  }

  return resultado;
}