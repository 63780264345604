import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const liveRoutes = [
    {
        key: 'contracted-services',
        path: `${AUTH_PREFIX_PATH}/contracted-services/:id`,
        component: React.lazy(() => import('views/app-views/reports/contracted-services/index')),
    }
];

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users/index')),
    },
    {
        key: 'roles',
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import('views/app-views/roles/index')),
    },
    {
        key: 'contacts',
        path: `${APP_PREFIX_PATH}/contacts`,
        component: React.lazy(() => import('views/app-views/contacts/index')),
    },
    {
        key: 'hierarchies',
        path: `${APP_PREFIX_PATH}/hierarchies`,
        component: React.lazy(() => import('views/app-views/hierarchies/index')),
    },
    {
        key: 'business-relationships',
        path: `${APP_PREFIX_PATH}/business-relationships`,
        component: React.lazy(() => import('views/app-views/business-relationships/index')),
    },
    {
        key: 'companies',
        path: `${APP_PREFIX_PATH}/companies`,
        component: React.lazy(() => import('views/app-views/companies/index')),
    },
    {
        key: 'domains',
        path: `${APP_PREFIX_PATH}/domains`,
        component: React.lazy(() => import('views/app-views/domains/index')),
    },
    {
        key: 'bills-categories',
        path: `${APP_PREFIX_PATH}/bills-categories`,
        component: React.lazy(() => import('views/app-views/bills-categories/index')),
    },
    {
        key: 'bills-to-pays',
        path: `${APP_PREFIX_PATH}/bills-to-pays`,
        component: React.lazy(() => import('views/app-views/bills-to-pays/index')),
    },
    {
        key: 'payment-statuses',
        path: `${APP_PREFIX_PATH}/payment-statuses`,
        component: React.lazy(() => import('views/app-views/payment-statuses/index')),
    },
    {
        key: 'bills-to-receivers',
        path: `${APP_PREFIX_PATH}/bills-to-receivers`,
        component: React.lazy(() => import('views/app-views/bills-to-receivers/index')),
    },
    {
        key: 'cashiers',
        path: `${APP_PREFIX_PATH}/cashiers`,
        component: React.lazy(() => import('views/app-views/cashiers/index')),
    },
    {
        key: 'installments-to-be-paid',
        path: `${APP_PREFIX_PATH}/installments-to-be-paid`,
        component: React.lazy(() => import('views/app-views/installments-to-be-paid/index')),
    },
    {
        key: 'installments-to-be-receive',
        path: `${APP_PREFIX_PATH}/installments-to-be-receive`,
        component: React.lazy(() => import('views/app-views/installments-to-be-receive/index')),
    },
    {
        key: 'cashier-annuall-report',
        path: `${APP_PREFIX_PATH}/cashier-annuall-report`,
        component: React.lazy(() => import('views/app-views/reports/cashier-annuall-report/index')),
    },
    {
        key: 'cashier-month-report',
        path: `${APP_PREFIX_PATH}/cashier-month-report`,
        component: React.lazy(() => import('views/app-views/reports/cashier-month-report/index')),
    },
    {
        key: 'entry-predictions',
        path: `${APP_PREFIX_PATH}/entry-predictions`,
        component: React.lazy(() => import('views/app-views/reports/entry-predictions/index')),
    },
    {
        key: 'payment-forecast',
        path: `${APP_PREFIX_PATH}/payment-forecast`,
        component: React.lazy(() => import('views/app-views/reports/payment-forecast/index')),
    },
    {
        key: 'recurrent-jobs',
        path: `${APP_PREFIX_PATH}/recurrent-jobs`,
        component: React.lazy(() => import('views/app-views/reports/recurrent-jobs/index')),
    },
    {
        key: 'closed-jobs',
        path: `${APP_PREFIX_PATH}/closed-jobs`,
        component: React.lazy(() => import('views/app-views/reports/closed-jobs/index')),
    },
    {
        key: 'sumary-services',
        path: `${APP_PREFIX_PATH}/sumary-services`,
        component: React.lazy(() => import('views/app-views/reports/sumary-services/index')),
    },
    {
        key: 'fixed-investiments',
        path: `${APP_PREFIX_PATH}/fixed-investiments`,
        component: React.lazy(() => import('views/app-views/reports/fixed-investiments/index')),
    },
    {
        key: 'hosts',
        path: `${APP_PREFIX_PATH}/hosts`,
        component: React.lazy(() => import('views/app-views/hosts/index')),
    },
    {
        key: 'services',
        path: `${APP_PREFIX_PATH}/services`,
        component: React.lazy(() => import('views/app-views/services/index')),
    },
    {
        key: 'late-payment',
        path: `${APP_PREFIX_PATH}/late-payment`,
        component: React.lazy(() => import('views/app-views/late-payment/index')),
    },
    {
        key: 'leads',
        path: `${APP_PREFIX_PATH}/leads`,
        component: React.lazy(() => import('views/app-views/leads/index')),
    },
    {
        key: 'notices-templates',
        path: `${APP_PREFIX_PATH}/notices-templates`,
        component: React.lazy(() => import('views/app-views/notices-templates/index')),
    },
    {
        key: 'templates',
        path: `${APP_PREFIX_PATH}/templates`,
        component: React.lazy(() => import('views/app-views/templates/index')),
    },
    {
        key: 'newsletters',
        path: `${APP_PREFIX_PATH}/newsletters`,
        component: React.lazy(() => import('views/app-views/newsletter/index')),
    },
    {
        key: 'email-list',
        path: `${APP_PREFIX_PATH}/email-list`,
        component: React.lazy(() => import('views/app-views/email-list/index')),
    },
    {
        key: 'formalization',
        path: `${APP_PREFIX_PATH}/formalization`,
        component: React.lazy(() => import('views/app-views/formalization/index')),
    },
    {
        key: 'automations',
        path: `${APP_PREFIX_PATH}/automations`,
        component: React.lazy(() => import('views/app-views/automations/index')),
    },
    {
        key: 'edit-help',
        path: `${APP_PREFIX_PATH}/edit-help`,
        component: React.lazy(() => import('views/app-views/edit-help/index')),
    },
    {
        key: 'help',
        path: `${APP_PREFIX_PATH}/help`,
        component: React.lazy(() => import('views/app-views/help/index')),
    },
    {
        key: 'scrumboard',
        path: `${APP_PREFIX_PATH}/scrumboard/:project_id`,
        component: React.lazy(() => import('views/app-views/crm/index')),
    },
    {
        key: 'projects',
        path: `${APP_PREFIX_PATH}/projects`,
        component: React.lazy(() => import('views/app-views/projects/index')),
    },
]